<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div v-if="project && unitType">
      <banner
        :project="unitType"
        :store="store"
        :imageIndex="imageIndex"
        :normalGalleries="normalGalleries"
      ></banner>
      <div class="project--container">
        <div class="left--col">
          <project-header :project="project" :unitType="unitType" />
          <attachment :project="project" />
          <detail :project="project" :unitType="unitType" />
          <facility :facilities="facilities"></facility>
          <!--        <area-detail :project="project" />-->
          <panorama :panoEmbedUrl="unitType.pano_embed_url" />
          <units :units="units" />
          <description :description="project.project_desc" :listingType="project.listing_type" />
          <!--        <operational-hour :project="project" />-->
          <!--        <amenity :amenities="project.amenities" />-->
          <client-only>
            <project-map :project="project" />
          </client-only>
          <mortgage-calculator :project="project" :projectUnitType="unitType"></mortgage-calculator>
          <nearby-places :nearbyPlaces="project.nearby_places" />
          <similar :uuid="project.uuid" :project="project" />
        </div>
        <div class="right--col">
          <project-info :project="project" />
          <action
            :project="project"
            type="unitType"
            :agentUuid="agentUuid"
            :listingUuid="unitType.uuid"
          />
          <!--        <contact :project="project" />-->
        </div>
      </div>
      <client-only>
        <modal-unit-detail />
      </client-only>
    </div>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/project-detail/banner';
import ProjectHeader from '@/components/project-detail/header';
// import OperationalHour from '@/components/project-detail/operational-hour';
import Attachment from '@/components/project-detail/attachment';
import Panorama from '@/components/project-detail/panorama';
import Detail from '@/components/project-detail/detail';
// import AreaDetail from '@/components/project-detail/area-detail';
import Description from '@/components/project-detail/description';
import Facility from '@/components/project-detail/facility';
// import Amenity from '@/components/project-detail/amenity';
import ProjectMap from '@/components/project-detail/map';
import Action from '@/components/project-detail/action';
import Similar from '@/components/project-detail/similar';
import Units from '@/components/project-detail/units';
import NearbyPlaces from '@/components/project-detail/nearby-places';
import MortgageCalculator from '@/components/project-detail/mortgage-calculator';
import ProjectInfo from '@/components/project-detail/project-info';

import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapGetters, mapState } from 'vuex';

import ModalUnitDetail from '@/components/project-detail/modals/unit-detail';
export default {
  mixins: [HelperMixin, LodashMixin],
  components: {
    ModalUnitDetail,
    DefaultLayout,
    // OperationalHour,
    Banner,
    ProjectHeader,
    Attachment,
    Panorama,
    Detail,
    // AreaDetail,
    Description,
    Facility,
    // Amenity,
    ProjectMap,
    Action,
    Similar,
    NearbyPlaces,
    Units,
    MortgageCalculator,
    ProjectInfo,
  },
  data: () => ({
    store: 'v2/unitType',
    hasExited: false,
  }),
  computed: {
    ...mapState({
      unitType: (state) => state.v2.unitType.detail,
      project: (state) => state.v2.unitType.project,
      agentUuid: (state) => state.v2.project.agentUuid,
      isLoading: (state) => state.global.isLoading,
      isLoggedIn: (state) => state.global.loggedIn,
      imageIndex: (state) => state.v2.unitType.imageIndex,
      user: (state) => state.global.user,
    }),
    ...mapGetters({
      normalGalleries: 'v2/unitType/galleries',
      facilities: 'v2/unitType/facilities',
      units: 'v2/unitType/units',
    }),
    typeString() {
      return this.$t('general.buy');
    },
    propertyTypeString() {
      if (this.project && this.project.property_type) {
        return this.project.property_type;
      } else {
        return '';
      }
    },
    breadcrumbs() {
      if (!this.project || !this.unitType) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: `${this.typeString} ${this.propertyTypeString}`,
          to: `/cari/${this.propertyTypeString.toLowerCase()}`,
        },
        {
          text: this.project.project_name,
          to: this.project.links.detail,
        },
        {
          text: this.unitType.name,
          to: this.unitType.links ? this.unitType.links.detail : this.$router.currentRoute.path,
        },
      ];
    },
    jsonldBreadcrumb() {
      if (!this.project || !this.unitType) return {};
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: `${this.typeString} ${this.propertyTypeString}`,
            item: baseUrl + `/cari/${this.propertyTypeString.toLowerCase()}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: this.project.project_name,
            item: baseUrl + this.project.links.detail,
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: this.unitType.name,
            item: this.unitType.links
              ? baseUrl + this.unitType.links.detail
              : baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      if (!this.project || !this.unitType) return {};
      return this.getUnitTypeJsonld({
        baseUrl: this.$store.state.global.baseUrl,
        path:
          this.unitType && this.unitType.links
            ? this.unitType.links.detail
            : this.$router.currentRoute.path,
        project: this.project,
        unitType: this.unitType,
        galleries: this.unitType.photos,
      });
    },
  },
  async fetch({ store, params, error, route, redirect }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    let unitType;
    try {
      unitType = await store.dispatch('v2/unitType/getDetail', params.hashId);
    } catch (e) {
      error(new Error(e.message), 404);
    }

    if (unitType && unitType.links) {
      if (route.path !== unitType.links.detail) {
        redirect({ path: unitType.links.detail }, 301);
      }
    }

    if (store.state.global.loggedIn) {
      await store.dispatch('v2/search/getShortlistUuids', {
        listingType: store.state.v2.masters.listingTypeEnum.PRIMARY,
      });
    }

    const seoTitle = store.getters['v2/unitType/seoTitle'];
    const seoDesc = store.getters['v2/unitType/seoDesc'];
    // const unitType = store.state.v2.unitType.detail;
    const baseUrl = store.state.global.baseUrl;
    const primaryPhoto = store.getters['v2/unitType/primaryPhoto'];

    let photoUrl = '';
    if (primaryPhoto && Object.keys(primaryPhoto).length > 0) {
      photoUrl = primaryPhoto.url;
    } else if (unitType && unitType.photos && Object.keys(unitType.photos).length > 0) {
      photoUrl = unitType.photos[0].url;
    }
    photoUrl = photoUrl.replace('/300', '/1080');

    const head = {
      title: seoTitle,
      meta: [
        {
          hid: 'og-url',
          property: 'og:url',
          content: baseUrl + (unitType.links ? unitType.links.detail : ''),
        },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        { hid: 'og-title', property: 'og:title', content: seoTitle },
        {
          hid: 'og-description',
          property: 'og:description',
          content: seoDesc,
        },
        {
          hid: 'description',
          name: 'description',
          content: seoDesc,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'BeliRumah' },
        {
          hid: 'og-image',
          property: 'og:image',
          content: photoUrl,
        },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + (unitType.links ? unitType.links.detail : ''),
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  beforeDestroy() {
    this.hasExited = true;
  },
  async mounted() {
    this.debounceCheckVisitor = this.debounce(this.checkVisitor, 3000);
    this.debounceCheckVisitor();

    // await this.$store.dispatch('v2/project/getProject', this.$route.params.hashId);
    const catalogAgentUuid = sessionStorage.getItem('CTGAID');
    if (catalogAgentUuid) {
      this.$store.commit('v2/unitType/SET_AGENT_UUID', catalogAgentUuid);
    }
  },
  methods: {
    async checkVisitor() {
      // Pengecekan apakah login atau tidak di-take out dulu untuk reduce friction

      // if (!this.hasExited) {
      //   await this.$store.dispatch('global/fetch');
      //   if (!this.isLoggedIn) {
      //     this.$modal.show('modal-login', { dismissable: false });
      //   } else if (this.user && !this.user.phone_number) {
      //     this.$modal.show('modal-phone', { dismissable: false, from: 'detail' });
      //   } else {
      if (this.unitType) {
        let payload = {
          projectUnitTypeUuid: this.unitType.uuid,
        };
        await this.$store.dispatch('visitor/visit', payload);
      }
      //   }
      // }
    },
  },
};
</script>
